<template>
  <v-theme-provider dark>
    <base-section
      id="aboutnk"
      class="accent"
      space="56"
    >
      <base-icon class="mb-8">
      </base-icon>
      <base-section-heading
       color="transparent"
       title="회사 소개"
      >
   </base-section-heading>
      <v-container>
        경험과 솔루션을 드립니다.<br>
        (주)남강은 1976년 장립이래 국내 유수의 식품업체 및 특급호텔들과 긴밀한 기반으로 발전을 거듭새 오며 오늘에 이르렀습니다.<br>
        식품 산업 분야의 세척제와 살균소독제를 전문으로 생산해 오며, 기술력이 수반된 고품질의 제품을 경쟁력 있는 가격으로 제공하여 왔으며,
        또한 국내 환경에서 발생하는 각종 세척 에로사항을 신속히 해결해 드릴 수 있는 축적된 경험과 기술을 제공하여 왔습니다.
        <br><br>
        고품질의 제품, 사용자와의 긴밀한 유대, 풍부한 경험과 솔루션의 제공을 추구하는 폐사는 식품 분야의 선진화된 위생 시스템 구축을 위해 사용자와 함께 끊임 없이 발전해 가려 합니다.<br>
        이를 위해 다음 사항에 집중해 갈 것입니다.
        <br><br>
        최신 기술을 적용한 우수 품질의 제품을 경쟁력 있는 가격으로 공급<br>
        제품 사용과 검증 등 관련 기술 서비스 제공<br>
        축적된 경험과 솔루션으로 애로문제 신속해결<br>
        <br><br>
        한국의 식품산업용 세척제 및 살균소독제 산업의 중심에는 항상 남강이 함께하여 갈 것 입니다.
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionAboutnk',

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({

    }),
  }
</script>
